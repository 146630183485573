.ui.vertical.sidebar.menu {
  > .item {
    border-top: solid 1px #c6251b;
  }
}

.ui.top.sidebar.mobilemenu {
  background-color: #000000;
}

.sidebar .servicelink {
  border-top: 1px solid #c6251b;

  a {
    color: #ffffff;
  }
}
